/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from "./core/BaseHttpRequest";
import type { OpenAPIConfig } from "./core/OpenAPI";
import { AxiosHttpRequest } from "./core/AxiosHttpRequest";

import { AdminApi } from "./services/AdminApi";
import { AnalyticsApi } from "./services/AnalyticsApi";
import { AuthApi } from "./services/AuthApi";
import { BannerApi } from "./services/BannerApi";
import { CoinPaymentsConfigurationApi } from "./services/CoinPaymentsConfigurationApi";
import { ContentfulApi } from "./services/ContentfulApi";
import { CountryApi } from "./services/CountryApi";
import { CouponsApi } from "./services/CouponsApi";
import { ForgottenCodeApi } from "./services/ForgottenCodeApi";
import { InfluencerApi } from "./services/InfluencerApi";
import { NotificationsApi } from "./services/NotificationsApi";
import { NotificationsSetupApi } from "./services/NotificationsSetupApi";
import { NotifyMeApi } from "./services/NotifyMeApi";
import { OrdersApi } from "./services/OrdersApi";
import { PaymentApi } from "./services/PaymentApi";
import { PinCodeApi } from "./services/PinCodeApi";
import { PushRegistrationApi } from "./services/PushRegistrationApi";
import { ReferralApi } from "./services/ReferralApi";
import { ReferralCodeApi } from "./services/ReferralCodeApi";
import { ResourcesApi } from "./services/ResourcesApi";
import { RoundApi } from "./services/RoundApi";
import { SupportApi } from "./services/SupportApi";
import { TestApi } from "./services/TestApi";
import { UploadApi } from "./services/UploadApi";
import { UserApi } from "./services/UserApi";
import { WinnersApi } from "./services/WinnersApi";
import { NativeWalletApi } from "./services/NativeWalletApi";

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ClientApi {
  public readonly admin: AdminApi;
  public readonly analytics: AnalyticsApi;
  public readonly auth: AuthApi;
  public readonly banner: BannerApi;
  public readonly coinPaymentsConfiguration: CoinPaymentsConfigurationApi;
  public readonly contentful: ContentfulApi;
  public readonly country: CountryApi;
  public readonly coupons: CouponsApi;
  public readonly forgottenCode: ForgottenCodeApi;
  public readonly influencer: InfluencerApi;
  public readonly notifications: NotificationsApi;
  public readonly notificationsSetup: NotificationsSetupApi;
  public readonly notifyMe: NotifyMeApi;
  public readonly orders: OrdersApi;
  public readonly payment: PaymentApi;
  public readonly pinCode: PinCodeApi;
  public readonly pushRegistration: PushRegistrationApi;
  public readonly referral: ReferralApi;
  public readonly referralCode: ReferralCodeApi;
  public readonly resources: ResourcesApi;
  public readonly round: RoundApi;
  public readonly support: SupportApi;
  public readonly test: TestApi;
  public readonly upload: UploadApi;
  public readonly user: UserApi;
  public readonly winners: WinnersApi;
  public readonly nativeWallet: NativeWalletApi;

  public readonly request: BaseHttpRequest;

  constructor(
    config?: Partial<OpenAPIConfig>,
    HttpRequest: HttpRequestConstructor = AxiosHttpRequest
  ) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? "/api/v1",
      VERSION: config?.VERSION ?? "1.0.0",
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? "include",
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.admin = new AdminApi(this.request);
    this.analytics = new AnalyticsApi(this.request);
    this.auth = new AuthApi(this.request);
    this.banner = new BannerApi(this.request);
    this.coinPaymentsConfiguration = new CoinPaymentsConfigurationApi(
      this.request
    );
    this.contentful = new ContentfulApi(this.request);
    this.country = new CountryApi(this.request);
    this.coupons = new CouponsApi(this.request);
    this.forgottenCode = new ForgottenCodeApi(this.request);
    this.influencer = new InfluencerApi(this.request);
    this.notifications = new NotificationsApi(this.request);
    this.notificationsSetup = new NotificationsSetupApi(this.request);
    this.notifyMe = new NotifyMeApi(this.request);
    this.orders = new OrdersApi(this.request);
    this.payment = new PaymentApi(this.request);
    this.pinCode = new PinCodeApi(this.request);
    this.pushRegistration = new PushRegistrationApi(this.request);
    this.referral = new ReferralApi(this.request);
    this.referralCode = new ReferralCodeApi(this.request);
    this.resources = new ResourcesApi(this.request);
    this.round = new RoundApi(this.request);
    this.support = new SupportApi(this.request);
    this.test = new TestApi(this.request);
    this.upload = new UploadApi(this.request);
    this.user = new UserApi(this.request);
    this.winners = new WinnersApi(this.request);
    this.nativeWallet = new NativeWalletApi(this.request);
  }
}
