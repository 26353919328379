import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class NativeWalletApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  public getUserWallet(id?: any): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: `/admin/native-wallet/wallet/${id}`,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  public getUserWalletTransactions(
    id?: string,
    limit?: number | string,
    offset?: number | string
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: `/admin/native-wallet/transactions/${id}`,
      query: {
        limit: limit,
        offset: offset,
        sortBy: "createdAt",
        direction: "DESC",
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  public getUserWalletBalance(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: `/admin/native-wallet/balance`,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }
}
